import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IUser } from '../shared/common-interface';

export interface IPayment {
  status: string,
  requestedDate: string,
  printedDate: string,
  amount: string,
  user: string,
  isPrinted: boolean,
  isApproved?: boolean,
  isDenied?: boolean,
  approvalDate: string,
  id: string,
}
@Injectable({
  providedIn: 'root'
})

export class PaymentsService {
  public url = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public getPayments(): Observable<any> {
    return this.http.get(this.url + "/payment/get-payments")
      .pipe(
        map(this.transformPayments.bind(this))
      )
  }

  requestPayment(data) {
    return this.http.post<any>(this.url + "/payment/create-payment", data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    })
  }

  updatePayment(data: {
    isApproved: boolean;
    isDenied: boolean,
    ApprovalDate: string,
    ids: any
  }): Observable<any> {
    return this.http.patch<any>(this.url + "/payment/update-payment", data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    })
  }

  private transformPayments(response: any): IPayment[] {
    const resPayments: any = response.payments;
    let payments: IPayment[] = []
    resPayments.map((payment: any) => {
      payments.push({
        status: payment.Status,
        requestedDate: payment.RequestedDate ? moment(payment.RequestedDate).format('DD/MM/YYYY') : null,
        printedDate: payment.PrintedDate,
        amount: payment.Amount,
        user: payment.user.userId + ' - ' + payment.user.name + ' (' + payment.user.email + ')',
        isPrinted: payment.PrintedDate ? true : false,
        approvalDate: payment.ApprovalDate ? moment(payment.ApprovalDate).format('DD/MM/YYYY') : null,
        isApproved: payment.isApproved ? payment.isApproved : false,
        isDenied: payment.isDenied ? payment.isDenied : false,
        id: payment._id,
      })
    })
    return payments;
  }

  public dwollaAddCustomer() {
    const customerData = {
      firstName: 'Doe',
      lastName: 'John',
      email: 'doe.john@example.com',
      // ...other customer data
    };
    this.http.post(this.url + '/dwolla/create-customer', customerData)
      .subscribe(
        (response:any) => {
          // Handle success
        },
        error => {
          console.error('Error creating customer:', error);
          // Handle error
        }
      );
  }

  public dwollaAddFundingSourceForCustomer(id) {
    const customerData = {
      routingNumber: "222222226",
      accountNumber: "123456789",
      bankAccountType: "checking",
      name: "Jane Merchant - Checking 6789",
    };
    this.http.post(this.url + '/dwolla/customers/'+id+'/funding-sources', customerData)
      .subscribe(
        response => {
          console.log('Customer Fundin source added:', response);
          // Handle success
        },
        error => {
          console.error('Error adding funding source customer:', error);
          // Handle error
        }
      );
  }

  public dwollGetCustomers() {
    this.http.get(this.url + '/dwolla/customers')
      .subscribe(
        response => {
        },
        error => {
          console.error('Error fetching customer:', error);
        }
      );
  }


  public dwollGetCustomer() {

    this.http.get(this.url + '/dwolla/customer/doe.john@example.com')
      .subscribe(
        (response:any) => {
          let id = response._embedded.customers[0].id;
          this.dwollaAddFundingSourceForCustomer(id)
          // Handle success
        },
        error => {
          console.error('Error fetching customer:', error);
          // Handle error
        }
      );
  }
}


// URL: http://localhost:8080