import { Component, OnInit } from '@angular/core';
import { reverseArray } from 'src/app/shared/common-interface';
import { UserService } from '../../shared/user.service';
import { DisputesService } from '../payment-dispute-dialog/payment-dispute.service';

@Component({
  selector: 'app-disputes-list',
  templateUrl: './disputes-list.component.html',
  styleUrls: ['./disputes-list.component.scss']
})
export class DisputesListComponent implements OnInit {
  public isLoading: boolean = true;
  public pendingDisputes: any;
  public approvedDisputes: any;
  public deniedDisputes: any;
  columns: string[] = ['Receipt ID','Buyer', 'Current Amount', 'Amout Requested', 'Notes'];
  keys: string[] = ['recId','buyerName', 'currentAmount', 'correctAmount', 'notes'];
  tabs: string[] = ['Pending', 'Approved', 'Denied'];
  constructor(
    private service: DisputesService,
    private user: UserService,
  ) { }

  ngOnInit(): void {
    this.service.getDisputes().subscribe(disputes => {
      if (disputes.length) disputes = reverseArray(disputes)
      if (this.user && this.user.isAdmin) {
        this.columns.push('Action');
        this.keys.push('Approve');
      }
      this.pendingDisputes = disputes.filter(
        dispute => (dispute.status === 'pending'));
      this.approvedDisputes = disputes.filter(
        dispute => (dispute.status === 'approved'));
      this.deniedDisputes = disputes.filter(
        dispute => (dispute.status === 'rejected'));
      this.isLoading = false;
    });
  }
}
