import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { UntypedFormGroup, FormGroup } from '@angular/forms';
import { IItem } from 'src/app/shared/common-interface';
import { Router } from '@angular/router';
import * as html2pdf from 'html2pdf.js';

@Component({
  
  selector: 'app-receipt-detail',
  templateUrl: './receipt-detail.component.html',
  styleUrls: ['./receipt-detail.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class ReceiptDetailComponent {
  @ViewChild('content') content: ElementRef;

  action: string;
  dialogTitle: string;
  receipt: any;
  items: IItem[];
  public isLoading = true;
  submitted = false;
  isTrackingForm: boolean = false;
  receiptForm: UntypedFormGroup;
  showEditForm: boolean = false;
  editForm: FormGroup;
  isOldVersionOfReceipt: boolean = false;


  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<ReceiptDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.receipt = data.info
    this.action = data.action;
    this.isTrackingForm = true;
    this.isOldVersionOfReceipt = this.isOldReceipt(this.receipt.Items)

  }

  get f() {
    return this.receiptForm.controls;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public get title(): string {
    let heading = 'Receipts Details'
    return heading
  }

  public isItemsObjectArray(items): boolean {
    if (!items?.length) return;
    if (typeof items[0] === 'object') {
      return true
    }
    return false;
  }
  getQuantity(item): any {
    return Object.values(item)[0];
  }

  getUpc(item): any {
    return Object.keys(item)[0]
  }

  isOldReceipt(items) {
    if (!Array.isArray(items)) {
      return false;
    }
    for (let i = 0; i < items.length; i++) {
      const obj = items[i];
      const keys = Object.keys(obj);
      if (keys.length !== 1 || typeof obj[keys[0]] !== 'number') {
        return false;
      }
    }
    return true;
  }
  generatePDF() {
    let name = this.receipt.receiptId ? 'receipt-'+this.receipt.receiptId+'.pdf' : 'receipt.pdf'
    const options = {
      filename: name,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, letterRendering: true },
      jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
      margin: [40, 40, 40, 40],
      enableLinks: true,
      enableStyles: true,
      beforeAddPage: function (doc) {
        // Define the header content
        const header = 'Receipt Details';

        // Add the header to the top of the page
        doc.text(header, 40, 30);
      },
      afterPageContent: [
        function (doc) {
          this.beforeAddPage(doc);
        }
      ]
    };
    html2pdf().set(options).from(this.content.nativeElement).save();
   
  }

}
