import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit{
  @Input() tabs: any[];
  @Input() template: TemplateRef<{$implicit: any}>[];
  @Output() onTabIndexChanged = new EventEmitter<number>();

  selected = new UntypedFormControl(0);
  ngOnInit(): void {
  }

  onTabChanged($event) {
    this.onTabIndexChanged.emit($event.index)
  }
}
