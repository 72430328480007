<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <!-- Menu -->
    <div class="menu">
      <ng-scrollbar [style.height]="listMaxHeight + 'px'" visibility="hover">
        <ul class="list">
          <!-- <li>
            <div class="sidebar-profile clearfix">
              <div class="profile-img">
                <img src="assets/images/usrbig.jpg" alt="profile">
              </div>
              <div class="profile-info">
                <h3>Sarah Deo</h3>
                <p>Welcome Admin !</p>
              </div>
            </div>
          </li> -->
          <!-- Top Most level menu -->
          <ng-container *ngFor="let sidebarItem of sidebarItems">
            <li *ngIf="shouldShow(sidebarItem.allowedUsers)"
              [routerLinkActive]="sidebarItem.submenu.length != 0 ? 'active' : 'active-top'">
              <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title | translate}}</div>
              <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
                [ngClass]="[sidebarItem.class]" (click)="callToggleMenu($event, sidebarItem.submenu.length)"
                class="d-flex a-cente menu-top">
                <!-- <i-feather [name]="sidebarItem.icon" class="sidebarIcon"></i-feather> -->
                <i class="sidebarIcon fas float-start m-r-5" [ngClass]="sidebarItem.icon"></i>
                <span class="hide-menu">{{sidebarItem.title | translate}}
                </span>
              </a>
              <!-- First level menu -->
              <!-- <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
                          <li *ngFor="let sidebarSubItem1 of sidebarItem.submenu"
                            [routerLinkActive]="sidebarSubItem1.submenu.length > 0 ? '' : 'active'">
                            <a [routerLink]="sidebarSubItem1.submenu.length > 0 ? null : [sidebarSubItem1.path]"
                              (click)="callToggleMenu($event,sidebarSubItem1.submenu.length)" [ngClass]="[sidebarSubItem1.class]">
                              {{sidebarSubItem1.title | translate}}
                            </a> -->
              <!-- Second level menu -->
              <!-- <ul class="ml-menu-2" *ngIf="sidebarSubItem1.submenu.length > 0">
                              <li *ngFor="let sidebarSubItem2 of sidebarSubItem1.submenu"
                                [routerLinkActive]="sidebarSubItem2.submenu.length > 0 ? '' : 'active'">
                                <a [routerLink]="sidebarSubItem2.submenu.length > 0 ? null : [sidebarSubItem2.path]"
                                  (click)="callToggleMenu($event,sidebarSubItem2.submenu.length)"
                                  [ngClass]="[sidebarSubItem2.class]">
                                  {{sidebarSubItem2.title | translate}}
                                </a> -->
              <!-- Third level menu -->
              <!-- <ul class="ml-menu-3" *ngIf="sidebarSubItem2.submenu.length > 0">
                                  <li *ngFor="let sidebarSubItem3 of sidebarSubItem2.submenu"
                                    [routerLinkActive]="sidebarSubItem3.submenu.length > 0 ? '' : 'active'">
                                    <a [routerLink]="sidebarSubItem3.submenu.length > 0 ? null : [sidebarSubItem3.path]"
                                      (click)="callToggleMenu($event,sidebarSubItem3.submenu.length)"
                                      [ngClass]="[sidebarSubItem3.class]">
                                      {{sidebarSubItem3.title | translate}}
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul> -->
            </li>
          </ng-container>

        </ul>
      </ng-scrollbar>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>