<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card card-base">
          <div class="row clearfix">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="card">
                <div class="header">
                  <h2> <strong>Disputes</strong></h2>
                </div>
                <div class="body">
                  <div *ngIf="isLoading" [ngTemplateOutlet]="loader"></div>
                  <app-tabs [tabs]="tabs" [template]="[pending, approved, denied]">
                  </app-tabs>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #pending>
  <app-advance-table *ngIf="!isLoading" [columns]="columns" [keys]="keys" [data]="pendingDisputes" [type]="'disputes'">
  </app-advance-table>
</ng-template>

<ng-template #approved>
  <app-advance-table *ngIf="!isLoading" [columns]="columns" [keys]="keys" [data]="approvedDisputes" [type]="'disputes'">
  </app-advance-table>
</ng-template>

<ng-template #denied>
  <app-advance-table *ngIf="!isLoading" [columns]="columns" [keys]="keys" [data]="deniedDisputes" [type]="'disputes'">
  </app-advance-table>
</ng-template>
<ng-template #loader>
  <div class="loader">
    <div class="preloader pl-size-xs">
      <div class="spinner-layer pl-black">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  </div>

</ng-template>