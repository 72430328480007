<div class="addContainer w-500">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          {{title}}
        </div>
      </div>
    </div>
    <button mat-icon-button (click)="dialogRef.close()" class="modal-close-button" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <form class="m-20" [formGroup]="disputeForm" (ngSubmit)="onSubmit()">
      <div class="auth-form-group m-20">
        <mat-icon matSuffix class="auth-page-icon">money</mat-icon>
        <input type="text" formControlName="currentAmount" class="form-control auth-control" id="currentAmount"
          placeholder="Current Amount" [ngClass]="{ 'is-invalid': submitted && f.currentAmount.errors }">
        <div *ngIf="submitted && f.currentAmount.errors" class="invalid-feedback">
          <div *ngIf="f.currentAmount.errors.required">Current Amount is required</div>
        </div>
      </div>
      <div class="auth-form-group m-20">
        <mat-icon matSuffix class="auth-page-icon">money</mat-icon>
        <input type="text" formControlName="correctAmount" class="form-control auth-control" id="correctAmount"
          placeholder="Correct Amount" [ngClass]="{ 'is-invalid': submitted && f.correctAmount.errors }">
        <div *ngIf="submitted && f.correctAmount.errors" class="invalid-feedback">
          <div *ngIf="f.correctAmount.errors.required">Correct Amount is required</div>
        </div>
      </div>
      <div class="auth-form-group m-20">
        <mat-icon matSuffix class="auth-page-icon">notes</mat-icon>

        <!-- <input type="text" formControlName="notes" class="form-control auth-control" id="correctAmount"
          placeholder="Correct Amount" [ngClass]="{ 'is-invalid': submitted && f.correctAmount.errors }"> -->
        <textarea class="form-control notes" placeholder="Add Notes" matInput formControlName="notes" [ngClass]="{ 'is-invalid': submitted && f.correctAmount.errors }">
        </textarea>
        <div *ngIf="submitted && f.correctAmount.errors" class="invalid-feedback">
          <div *ngIf="f.correctAmount.errors.required">Correct Amount is required</div>
        </div>
      </div>
      

      <div class="d-flex a-center j-center">
        <button mat-flat-button color="primary"  type="submit">
          Save
        </button>
      </div>
    </form>
  </div>
</div>