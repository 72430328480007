import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IUser, transformCommitments } from '../shared/common-interface';
export interface IShipment {
  id: string,
  tracking_id: string,
  user: IUser,
  userName: string,
  commits: any[],
  active: boolean,
  createdOn: string,
  nonCommited?: boolean,
  commitsCount: number,
}
@Injectable({
  providedIn: 'root'
})

export class ShipmentsService {
  public url = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public getShipments(): Observable<any> {
    return this.http.get(this.url + "/shipment/get-shipments")
      .pipe(
        map(this.transformShipments.bind(this))
      )
  }

  public getShipmentByTracking(tracking: string): Observable<any> {
    return this.http.get(this.url + "/shipment/get/"+tracking)
      .pipe(
        map(this.transformShipments.bind(this))
      )
  }

  createShipment(data: {
    commits: string[],
    tracking_id:string,
    nonCommitted: boolean,
  }): Observable<any> {
    return this.http.post<any>(this.url + "/shipment/create-shipment", data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    })
  }

  updateShipment(data: any, id: string): Observable<any> {
    return this.http.patch(this.url+"/shipment/update-shipment/"+id, data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    });
  }

  private transformShipments(response: any): IShipment | IShipment[] {
  
    let shipments: IShipment[] = []
    if (!this.isArray(response.shipments)) {
      shipments.push(this.transformShipment(response.shipment))
    } else {
      response.shipments.map((shipment: any) => {
        shipments.push(this.transformShipment(shipment))
      })
    }
   
    return shipments;
  }

  private transformShipment(shipment: any) {
    if (!shipment) return
    return {
      id: shipment._id,
      tracking_id: shipment.tracking_id,
      user: shipment.user,
      userName: shipment.user.userId+ ' - '+shipment.user.name,
      commits: transformCommitments(shipment.commits),
      active: shipment.status === 'SHIPPED' ? true : false,
      createdOn: moment(shipment.created_at).format('YYYY-MM-DD HH:mm:ss'),
      commitsCount:  shipment.commits ? shipment.commits.length : 0,
      nonCommited: shipment.nonCommited ? shipment.nonCommited : false,
    }
  }

  private isArray(value: any): boolean {
    return Array.isArray(value);
  }
}