import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DisputesService {
  public url = environment.apiUrl;
  constructor(private http: HttpClient) { }


  createDispute(data): Observable<any> {
    return this.http.post<any>(this.url + "/dispute/create", data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    })
  }

  public getDisputes(): Observable<any> {
    return this.http.get(this.url + "/dispute/get", {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    }).pipe(map(this.transformDisputes.bind(this)))
  }

  transformDisputes(res): any {
    let returnDisputes = []
    let disputes = res.paymentDisputes;
    disputes.map(dispute => {
      returnDisputes.push({
        ...dispute,
        buyerName: dispute.createdBy.userId + ' - ' + dispute.createdBy.name + ' (' + dispute.createdBy.email + ')',
        recId: dispute?.receiptId?.receiptId ? dispute?.receiptId ?.receiptId : '--',
      }
      )
    })
    return returnDisputes;

  }


  updateDispute(data: {}, id: any): Observable<any> {
    return this.http.patch<any>(this.url + "/dispute/update/"+id, data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    })
  }
}
