import * as moment from "moment";
import isArray from 'lodash'
import { number, use } from 'echarts';

export interface ICommitments {
  id?: string;
  count: number;
  trackingNo: string;
  status: string;
  user: IUser;
  deal: IDeal,
  received: boolean;
  verified: boolean;
  errors: boolean;
  rewards: boolean;
  commitmentHistory: any;
  createdDate: string;
}

export interface ICommitmentRow {
  id: string,
  date: string,
  item: string,
  count: number,
  status: string,
  trackingNo: string,
  user: string,
  price: number,
  commission: number,
  total: number,
  verified: boolean,
  itemObj: IItem,
}

export interface IUser {
  _id: string,
  id: number,
  name: string,
  email: string,
  address: string,
  city: string,
  country: string,
  phone: number,
  role: string,
  verified: boolean,
  date: string,
  referredBy?: string,
  Balance?: IBalance,
  token?: string,
  userId:string,
  totalBalance?: any,
  claimedBalance?: any,
  unclaimedBalance?: any,
}

export interface IWareHouse {
  warehouseName: string,
  address: string,
  isActive: boolean,
  id:string
}

export interface IBalance {
  totalBalance: string,
  ClaimedBalance: string,
  UnClaimedBalance: string
}
export interface IDeal {
  id?: string,
  title: string;
  price?: number;
  commission?: number;
  isBelowCost?: boolean;
  isActive?: boolean;
  image: string;
  items?: IItem[];
  totalPrice?: number;
}

export interface IItem {
  id?: string;
  itemTitle: string;
  model: string;
  itemColor: string;
  capacity?: number;
  capacityPerPerson?: number;
  upc: number;
  links: IMerchantInfo[];
  spaceTaken?: number;
  requireSerial?: boolean;
  price?: number,
}

export interface IMerchantInfo {
  domain: string;
  link: string;
  price?: number;
}

export interface ICommit {
  count: number,
  trackingNo?: string,
  status: string,
  dealId: string,
  dealItemId: string,
  commitId?: string
}

export function transformDeal(deal: any, items?:any): IDeal {
  if (!deal) return;
  return ({
    id: deal._id,
    title: deal.Title,
    price: deal.Price,
    commission: deal.Commission,
    isBelowCost: deal.isBelowCost,
    isActive: deal.isActive,
    image: deal.Image,
    totalPrice: parseFloat(deal.Price) + parseFloat(deal.Commission),
    items: transformItems(items),
  })
}

export function transformItems(items: any): IItem[] {
  let itemsArray: IItem[] = [];
  if (isArray(items)) {
    items.map(item => {
      itemsArray.push(transformItem(item))
    })
  }
  else itemsArray.push(transformItem(items))
  return itemsArray;
}

export function transformItem(item: any): IItem {
  if (!item) return;

  return ({
    id: item._id,
    itemTitle: item.Title,
    model: item.Model,
    itemColor: item.Color,
    capacity: item.MaxQuantitySpace,
    capacityPerPerson: item.MaxQuantityPerPerson,
    upc: item.UPC,
    spaceTaken: item.SpaceTaken,
    links: transformLinks(item.Links),
    requireSerial: item.requireSerial,
    price: parseFloat(item.Price),
  })
}

export function transformUsers(users: any): IUser[] {
  let mappedUsers: IUser[] = [];
  users.map(user => {
    mappedUsers.push(
      transformUser(user)
    )
  });
  return mappedUsers
}

export function transformDeals(deals: any): IDeal[] {
  let mappedDeals: IDeal[] = [];
  deals.map(deal => mappedDeals.push(transformDeal(deal, deal.Items)));
  return mappedDeals;
}

export function transformUser(user: any): IUser {
  if (!user) return;
  return (
    {
      _id: user._id,
      id: user.id,
      name: user.name,
      email: user.email,
      address: user.address,
      city: user.city,
      country: user.country,
      phone: user.phone,
      role: user.role,
      verified: user.verified,
      userId: user.userId,
      referredBy: user.referredBy ? user.referredBy : null,
      date: moment(user.date).format('DD/MM/YYYY'),
      Balance: user.Balance,
      totalBalance:  user?.Balance?.totalBalance,
      unclaimedBalance: user?.Balance?.UnClaimedBalance,
      claimedBalance: user?.Balance?.ClaimedBalance,
    }
  );
}

function isArray(value: any): boolean {
  return Array.isArray(value);
}

export function transformLinks(links: any): IMerchantInfo[] {
  let mappedLinks : IMerchantInfo[] = [];
  links.map((link: any) => {
    mappedLinks.push({
      domain: link.domain, 
      link : link.link,
    })
  });
  return mappedLinks
}

export function transformCommitments(response: any): ICommitments[] {
  const resCommits: any = response;
  let commitments: ICommitments[] = [];
  resCommits.map(commit => {
    let commitment: ICommitments = {
      id: commit._id,
      count: commit.count,
      trackingNo: commit.trackingNo,
      status: commit.status,
      user: transformUser(commit.user),
      deal: transformDeal(commit.deal, commit.dealItem),
      received: commit.Received,
      verified: commit.Verified,
      errors: commit.Errors,
      rewards: commit.Rewards,
      commitmentHistory: commit.commitmentHistory,
      createdDate: moment(commit.createdDate).format('DD/MM/YYYY'),
    }
    commitments.push(commitment);
  });
  return commitments;
}


export function transformTableData(commit: ICommitments): ICommitmentRow {
  let row: ICommitmentRow = {
    id: commit.id,
    date: commit.createdDate,
    item: commit.deal?.items[0]?.itemTitle + ' ' + commit.deal?.items[0]?.itemColor,
    count: commit.count,
    status: commit.status,
    trackingNo: commit.trackingNo,
    user: commit.user ? commit.user.name + ' (' + commit.user.email + ')' : '--',
    price: commit.deal?.price,
    commission: commit.deal?.commission,
    verified: commit.verified,
    total: commit.deal?.totalPrice,
    itemObj: commit.deal?.items[0],
  }
  return row;
}
// export function getStatus(commit: ICommitments): string {
//   if (commit.verified) return 'Verified'
//   else if (commit.errors) return 'Fault Item'
//   else return 'Committed'
// }

export function transformAllTableData(commits: ICommitments[] | any): ICommitmentRow[] {
  let rows: ICommitmentRow[] = [];
  commits.map(commit => rows.push(transformTableData(commit)))
  return rows;
}

export function reverseArray(arr: any): any[] {
  if (!arr.length) return
  const n = arr.length;
  for (let i = 0; i < n/2; i++) {
    [arr[i], arr[n-i-1]] = [arr[n-i-1], arr[i]];
  }
  return arr;
}
