import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { transformCommitments, transformAllTableData } from '../shared/common-interface';

@Injectable({
  providedIn: 'root'
})

export class commitmentService {
  public url = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public getCommitments(): Observable<any> {
    return this.http.get(this.url + "/commitment/get-commitments")
      .pipe(
        map((res: any) => res.commitments),
        map(transformCommitments.bind(this)),
        map(transformAllTableData.bind(this))
      )
  }

  public getCommitmentsWithMissingTrackingNo(): Observable<any> {
    return this.http.get(this.url + "/commitment/missing-tracking")
      .pipe(
        map((res: any) => res.commitments),
        map(transformCommitments.bind(this)),
        map(transformAllTableData.bind(this))
      )
    ;
  }
}
