import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICommit, IDeal, transformDeal, transformDeals } from '../shared/common-interface';
export interface IUpcResponse {
  ean: string;
  title: string;
  description: string;
  upc: string;
  brand: string;
  model: string;
  color: string;
  size: string;
  dimension: string;
  weight: string;
  category: string;
  currency: string;
  lowest_recorded_price: number;
  highest_recorded_price: number;
  images: string[];

  offers: {
    merchant: string;
    domain: string;
    title: string;
    currency: string;
    list_price: string;
    price: number;
    shipping: string;
    condition: string;
    availability: string;
    link: string;
    updated_t: number;
  }[];
}
@Injectable({
  providedIn: 'root'
})
export class DealService {
  public url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getUPC(upc): Observable<IUpcResponse> {
    return this.http
      .post(this.url + '/deal/get-item-by-upc', { upc })
      .pipe(map((res: any) => res.items[0]));
  }

  addDeal(data: any): Observable<any> {
    return this.http.post(this.url+"/deal/create-deal", data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    });
  }

  getDeal(id): Observable<IDeal> {
    return this.http.get(this.url+"/deal/get-deal/"+id, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    }).pipe(map((res: any) => transformDeal(res, res.Items)));
  }

  getDeals(): Observable<IDeal[]> {
    return this.http.get(this.url+"/deal/get-Deals", {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    }).pipe(map(res => transformDeals(res)));
  }

  deleteDeal(id: string): Observable<any> {
    return this.http.delete<any>(this.url + "/deal/delete/"+id, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    })
  }

  addCommit(commitInfo: ICommit) {
    return this.http.post<any>(this.url + "/commitment/create-commitment", commitInfo, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    })
  }

  updateDeal(data: any, id: string): Observable<any> {
    return this.http.patch(this.url+"/deal/update-deal/"+id, data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    });
  }

  updateItems(data: any): Observable<any> {
    return this.http.patch(this.url+"/deal/update-items", data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    });
  }

  updateCommitCount(data: any, id: string): Observable<any> {
    return this.http.patch(this.url+"/commitment/update/"+id, data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    });
  }
} 