<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          {{title}}
        </div>
      </div>
    </div>
    <button mat-icon-button (click)="dialogRef.close()" class="modal-close-button" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <form class="register-form m-4" [formGroup]="advanceTableForm" (ngSubmit)="submit">
      <div *ngIf="isUserBalanceForm" class="row">
        <div class="  col-sm-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width " appearance="outline">
            <mat-label>Total Balance</mat-label>
            <input matInput formControlName="totalBalance"  id="totalBalance" 
              [ngClass]="{ 'is-invalid': submitted && f.totalBalance.errors }">
            <mat-error *ngIf="advanceTableForm.get('totalBalance').hasError('required')">
              Total Balance is required!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="example-full-width " appearance="outline">
            <mat-label>Un claimed Balance</mat-label>
            <input matInput formControlName="UnClaimedBalance"  id="UnClaimedBalance" 
              [ngClass]="{ 'is-invalid': submitted && f.UnClaimedBalance.errors }">
            <mat-error *ngIf="advanceTableForm.get('UnClaimedBalance').hasError('required')">
              Un claimed Balance is required!
            </mat-error>
          </mat-form-field>

          <mat-form-field class="example-full-width " appearance="outline">
            <mat-label>Claimed Balance</mat-label>
            <input matInput formControlName="claimedBalance"  id="claimedBalance" 
              [ngClass]="{ 'is-invalid': submitted && f.claimedBalance.errors }">
            <mat-error *ngIf="advanceTableForm.get('claimedBalance').hasError('required')">
              ClaimedBalance is required!
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isUserEditForm" class="row">
        <div class="  col-sm-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width " appearance="outline">
            <mat-label>Select Role</mat-label>
            <mat-select formControlName="role" required>
              <mat-option [value]="'buyer'">
                Buyer
              </mat-option>
              <mat-option [value]="'admin'">
                Admin
              </mat-option>
              <mat-option [value]="'staff'">
                Staff
              </mat-option>
            </mat-select>
            <mat-error *ngIf="advanceTableForm.get('role').hasError('required')">
              Must Select a Role!
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isPaymentForm" class="row">
        <div class="  col-sm-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width " appearance="outline">
            <mat-label>Approve Payment</mat-label>
            <mat-select formControlName="approve" required>
              <mat-option [value]="'approve'">
                Approve
              </mat-option>
              <mat-option [value]="'deny'">
                Deny
              </mat-option>
            </mat-select>
            <mat-error *ngIf="advanceTableForm.get('approve').hasError('required')">
              Must Select a Role!
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isCommitCountForm" class="row">
        <div class="  col-sm-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width " appearance="outline">
            <mat-label>Update Commit Count</mat-label>
            <input matInput formControlName="newCount"  id="tracking" 
              [ngClass]="{ 'is-invalid': submitted && f.tracking.errors }">
            <mat-error *ngIf="advanceTableForm.get('newCount').hasError('required')">
              Must Add a Count.
            </mat-error>
            <mat-error *ngIf="advanceTableForm.get('newCount').hasError('numberOnly')">
             Enter a valid number!
            </mat-error>
            <mat-error *ngIf="advanceTableForm.get('newCount').hasError('countRange')">
              {{advanceTableForm.controls.newCount.errors.message}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isTrackingForm" class="row">
        <div class="  col-sm-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width " appearance="outline">
            <mat-label>Add Tracking No.</mat-label>
            <input matInput formControlName="trackingNo"  id="tracking" 
              [ngClass]="{ 'is-invalid': submitted && f.tracking.errors }">
            <mat-error *ngIf="advanceTableForm.get('trackingNo').hasError('required')">
              Must Add a tracking No.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="isDisputeForm" class="row">
        <div class="  col-sm-12 col-md-12 col-sm-12 mb-2">
          <mat-form-field class="example-full-width " appearance="outline">
            <mat-label>Approve Dispute</mat-label>
            <mat-select formControlName="approve" required>
              <mat-option [value]="'approve'">
                Approve
              </mat-option>
              <mat-option [value]="'reject'">
                Deny
              </mat-option>
            </mat-select>
            <mat-error *ngIf="advanceTableForm.get('approve').hasError('required')">
              Must Select a Role!
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
          <div class="example-button-row">
            <button mat-raised-button color="primary" [disabled]="!advanceTableForm.valid"
              [mat-dialog-close]="1" (click)="confirmApprove()">Save</button>
            <button mat-raised-button color="warn" (click)="onNoClick()" tabindex="-1">Cancel</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>