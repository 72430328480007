<section>
  <div class="container-fluid">
    <!-- Example Tab -->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="body">
            <mat-tab-group (selectedTabChange)="onTabChanged($event)">
              <ng-container *ngFor="let tab of tabs; let i = index">
                <mat-tab [label]="tab">
                  <br>
                  <ng-template [ngTemplateOutlet]="template[i]" [ngTemplateOutletContext]="{ $implicit: tab }">
                  </ng-template>
                </mat-tab>
              </ng-container>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>