<div class="addContainer">
  <div class="modalHeader d-block">
    <div class="f-right m-r-30 modal-about d-flex a-center">
      <button color="primary" mat-stroked-button (click)="generatePDF()" mat-tooltip="Download Report">
        <i class="fa fa-download"></i> Download
      </button>
    </div>
    <button mat-icon-button (click)="dialogRef.close()" class="modal-close-button" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <div id="content" #content class="receipt-header">
      <div class="d-flex a-center j-between">
        <img style="width: 150px;" class="w-140" src="assets/images/logo.png" />

        <h3 style="margin-bottom: 0px;"><span class="float-end">RECEIPT #{{receipt.receiptId}}</span></h3>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <div class="float-start">
            <address>
              <p class="font-bold">FROM :</p>
              <p class="text-muted">
                BUYGETREWARDS.NET
                <br> 89 Christiana Road,
                <br> New Castle,
                <br> DE 19720,
              </p>
            </address>
          </div>
          <div class="float-end text-end">
            <address>
              <p class="addr-font-h3 font-bold">TO :</p>
              <p class="font-bold addr-font-h4">{{receipt?.Buyer?.name}}</p>
              <p class="text-muted m-l-30">
                {{receipt?.Buyer?.userId}}
                <br> {{receipt?.Buyer?.email}},
                <br> {{receipt?.Buyer?.phone}},
              </p>
            </address>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table table-borderless medicine-list">
            <tr *ngIf="receipt.createdAt">
              <td><i class="fas fa-clock pill-style"></i> Created On</td>
              <td class="text-end w-25">
                <span class="badge-outline">{{receipt.createdAt}}</span>
              </td>
            </tr>
            <tr>
              <td><i class="fas fa-box-open pill-style"></i> Tracking Number</td>
              <td class="text-end w-25">
                <span class="badge-outline">{{receipt.TrackingNumber}}</span>
              </td>
            </tr>
            <tr>
              <td><i class="fas fa-money-bill pill-style"></i> Total Price</td>
              <td class="text-end w-25">
                <span class="badge-outline">{{receipt.totalPrice}}</span>
              </td>
            </tr>
            <tr>
              <td><i class="fas fa-clipboard-list pill-style"></i> No. of Items</td>
              <td class="text-end w-25">
                <span class="badge-outline">{{receipt.TotalQuantityOfItems}}</span>
              </td>
            </tr>
            <tr>
            </tr>
          </table>
          <ul *ngIf="!isOldVersionOfReceipt"
            class="list-unstyled user-progress list-unstyled-border list-unstyled-noborder">
            <ng-container *ngFor="let item of receipt.Items; let i = index">
              <li class="lecture-list">
                <h3 class="m-20 col-primary">${{receipt.Items[i].price}}</h3>
                <div class="media-title font-16">{{receipt.Items[i].itemTitle}}
                  <div class="d-flex">
                    <div class="set-flex">
                      <div matTooltip="UPC" class="text-job text-muted mb-0">UPC: {{receipt.Items[i].upc}}</div>
                      <div *ngIf="item.Serial" class="text-muted" matTooltip="Serial" (click)="item.SerialEditable = true">
                        Serial: {{item.Serial}}
                      </div>
                    </div>
                    <div class="m-l-30">
                      <div class="text-job text-muted font-16 mb-0" matTooltip="Color">Color: {{receipt.Items[i].itemColor}}</div>
                      <div class="text-job text-muted mb-0" matTooltip="Model">Model : {{receipt.Items[i].model}}</div>
                    </div>
                  </div>
                </div>
              </li>
            </ng-container>
          </ul>

          <ul *ngIf="isOldVersionOfReceipt"
            class="list-unstyled user-progress list-unstyled-border list-unstyled-noborder">
            <ng-container *ngFor="let item of receipt.Items; let i = index">
              <li class="old-list before-update lecture-list w-per-90" [attr.data-number]="getQuantity(item)">
                <div class="set-flex">
                  <div class="media-title font-16">UPC</div>
                </div>
                <div class="ms-auto">
                  <div class="media-title font-16">{{getUpc(item)}}</div>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <div class="loader">
    <div class="preloader pl-size-xs">
      <div class="spinner-layer pl-black">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>