<div class="addContainer">
  <div class="modalHeader">
    <div class="editRowModal">
      <div class="modalHeader clearfix">
        <div class="modal-about">
          {{title}}
        </div>
      </div>
    </div>
    <button mat-icon-button (click)="dialogRef.close()" class="modal-close-button" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <app-advance-table *ngIf="!isLoading" [columns]="columns" [keys]="keys" [data]="committments"
      [shouldShowAction]="false" [type]="'editShipments'" [selectedRows]="alreadyAddedCommits" [tracking]="data.info.tracking_id" [shipmentId]="data.info.id">
    </app-advance-table>
  </div>
</div>