import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '../service/auth.service';
import { UserService } from '../../shared/user.service'
import { ROLES } from '../../shared/roles';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardAdmin implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.currentUserValue.token) {
      const userRole = this.userService.userRole;

      if (userRole === ROLES.ADMIN) {
        return true;
      } else {
        this.router.navigate(['/lost']);
        return false;
      }
    } else {
      this.router.navigate(['/authentication/signin']);
      return false;
    }
  }
}
