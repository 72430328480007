import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AuthGuardAdmin } from './core/guard/auth-admin.guard';
import { AuthGuardAdminAndBuyer } from './core/guard/auth-admin-and-buyer';
import { AuthGuardAdminAndStaff } from './core/guard/auth-admin-and-staff';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { FaqsComponent } from './extra-pages/faqs/faqs.component';
import { TermsAndConditionsComponent } from './extra-pages/terms-and-conditions/terms-and-conditions.component';
import { DisputesListComponent } from './receipts/disputes-list/disputes-list.component';
const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'users',
        canActivate: [AuthGuardAdmin],
        loadChildren: () =>
          import('./users-list/users-list.module').then(
            (m) => m.UsersListModule
          )
      },
      {
        path: 'deals',
        canActivate: [AuthGuardAdminAndBuyer],
        loadChildren: () =>
          import('./manage_deals/manage-deals.module').then(
            (m) => m.ManageDealsModule
          )
      },
      {
        path: 'commitments',
        canActivate: [AuthGuardAdminAndBuyer],
        loadChildren: () =>
          import('./commitments/commitments.module').then(
            (m) => m.CommitmentsModule
          )
      },
      {
        path: 'payments',
        canActivate: [AuthGuardAdminAndBuyer],
        loadChildren: () =>
          import('./payments/payments.module').then(
            (m) => m.PaymentsModule
          )
      },
      {
        path: 'tickets',
        canActivate: [AuthGuardAdminAndBuyer],
        loadChildren: () =>
          import('./tickets/tickets.module').then(
            (m) => m.TicketsModule
          )
      },
      {
        path:"shipments",
        canActivate: [AuthGuardAdminAndBuyer],
        loadChildren:()=>
          import('./Shipments/shipments.module').then(
            (m)=>m.ShipmentsModule,
          )        
      },
      {
        path: 'faqs',
        component: FaqsComponent,
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule
          )
      },
      {
        path: 'termsandconditions',
        component: TermsAndConditionsComponent,
        loadChildren: () =>
          import('./extra-pages/extra-pages.module').then(
            (m) => m.ExtraPagesModule
          )
      },
      {
        path: 'serials',
        canActivate: [AuthGuardAdmin],
        loadChildren: () =>
          import('./serials/serials.module').then(
            (m) => m.SerialsModule
          )
      },
      {
        path: 'items',
        canActivate: [AuthGuardAdmin],
        loadChildren: () =>
          import('./items/items.module').then(
            (m) => m.ItemsModule
          )
      },
      {
        path: 'reports',
        canActivate: [AuthGuardAdminAndStaff],
        loadChildren: () =>
          import('./reports/reports.module').then(
            (m) => m.ReportsModule
          )
      },
      {
        path: 'receiving',
        canActivate: [AuthGuardAdminAndStaff],
        loadChildren: () =>
          import('./receiver-terminal/receiver-terminal.module').then(
            (m) => m.ReceiverTerminalModule
          )
      },
      {
        path: 'receipts',
        loadChildren: () =>
          import('./receipts/receipts.module').then(
            (m) => m.ReceiptsModule
          )
      },
      {
        path: 'disputes',
        canActivate: [AuthGuardAdminAndBuyer],
        component: DisputesListComponent,
        loadChildren: () =>
          import('./receipts/receipts.module').then(
            (m) => m.ReceiptsModule
          )
      },
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
