import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AdvanceTableService } from './advance-table.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { SelectionModel } from '@angular/cdk/collections';
import { UnsubscribeOnDestroyAdapter } from '../shared/UnsubscribeOnDestroyAdapter';
import { FormDialogComponent } from './dialogs/form-dialog/form-dialog.component';
import { ShipmentDialogComponent } from '../Shipments/shipment-form-dialog/form-dialog.component';
import { PaymentDisputeDialogComponent } from '../receipts/payment-dispute-dialog/payment-dispute-dialog.component';
import { ReceiptDetailComponent } from '../receipts/receipt-detail/receipt-detail.component';
import isNil from 'lodash/isNil';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ShipmentsService } from '../Shipments/Shipment.service';
import { SnackbarService } from '../shared/snackbar.service';
import { GlobalConsts } from '../shared/global-consts';
import { Router } from '@angular/router';
@Component({
  selector: 'app-advance-table',
  templateUrl: './advance-table.component.html',
  styleUrls: ['./advance-table.component.sass'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class AdvanceTableComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit {
  @Input() columns: string[];
  @Input() keys: string[];
  @Input() data: any[];
  @Input() shouldShowAdd: boolean = false;
  @Input() selectionEnabled: boolean = false;
  @Input() shouldShowAction: boolean = false;
  @Input() type: string;
  @Input() tracking: string;
  @Input() shipmentId: string;
  @Input() selectedRows: any = [];
  @Output() selectedUser = new EventEmitter<any>();
  exampleDatabase: AdvanceTableService | null;
  dataSource: ExampleDataSource | null;
  selection = new SelectionModel<any>(true, []);
  id: number;
  advanceTable: any | null;
  trackingForm: UntypedFormGroup;

  constructor(
    private fb: FormBuilder,
    public httpClient: HttpClient,
    public dialog: MatDialog,
    public advanceTableService: AdvanceTableService,
    private snackBar: MatSnackBar,
    private shipmentService: ShipmentsService,
    private snackbar: SnackbarService,
    private router: Router
  ) {
    super();
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;
  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;
  contextMenuPosition = { x: '0px', y: '0px' };
  ngOnInit() {
    this.selection = new SelectionModel<any>(true, this.selectedRows);
    this.loadData();
    if (this.type === 'editShipments') {
      this.trackingForm = this.fb.group({
        trackingNo: [this.tracking, Validators.required],
      });
    }
  }

  updateShipment() {
    let commits: string[] = [];
    this.selection.selected.map(commit => {
      commits.push(commit.id)
    })
    this.shipmentService.updateShipment({
      commits, tracking_id: this.trackingForm.controls.trackingNo.value
    }, this.shipmentId)
      .subscribe((res: any) => {
        this.snackbar.showNotification(
          'snackbar-success',
          res.message,
          'bottom',
          'center'
        );
      }, (error) => {
        let message = error ? error : GlobalConsts.error;
        this.snackbar.showNotification(
          'snackbar-danger',
          message,
          'bottom',
          'center'
        );
      });
  }

  onActionClick(row, action:any) {
    if (this.type === 'users') {
      if (action == 'Balance') this.editBalance(row);
      else this.approveUser(row);
    }
    else if (this.type === 'payments') this.approvePayment(row);
    else if (this.type === 'shipments') this.editShipment(row);
    else if (this.type == 'commitments') this.editCommitment(row);
    else if (this.type === 'receipts') {
      if (action == 'Dispute') this.createDispute(row);
      else if(action == 'Details') this.receiptDetails(row);
      else if (action == 'Edit')this.router.navigate(['/receipts/edit', row._id], { state: { data: row } });

    }
    else if (this.type === 'disputes') this.approveDispute(row);
  }

  getActions(keys): string[] {
    let actionsString =  keys
    let actions = actionsString.split(",");
    return actions;
  }

  editCommitment(row) {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        info: row,
        type: this.type,
        action: this.keys[this.keys.length - 1]
      },
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
    });
  }

  editShipment(row) {
    const dialogRef = this.dialog.open(ShipmentDialogComponent, {
      data: {
        info: row,
        type: this.type,
        action: this.keys[this.keys.length - 1]
      },
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
    });
  }

  approveDispute(row) {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        info: row,
        type: this.type,
        action: this.keys[this.keys.length - 1]
      },
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
    });
  }


  createDispute(row) {
    const dialogRef = this.dialog.open(PaymentDisputeDialogComponent, {
      data: {
        info: row,
        type: this.type,
        action: this.keys[this.keys.length - 1]
      },
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
    });
  }

  receiptDetails(row) {
    const dialogRef = this.dialog.open(ReceiptDetailComponent, {
      data: {
        info: row,
        type: this.type,
        action: this.keys[this.keys.length - 1]
      },
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
    });
  }

  editBalance(row) {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        info: row,
        type: this.type,
        action: 'Balance'
      },
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
    });
  }

  approveUser(row) {
    let rows = this.selection.selected.length ? this.selection.selected : row;
    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        info: rows,
        type: this.type,
        action: this.getActions(this.keys[this.keys.length - 1])[0]
      },
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
    });
  }

  selectUser() {
    if (this.selection.selected.length > 1) {
      this.showNotification(
        'snackbar-danger',
        'Please Select only 1 user',
        'bottom',
        'center'
      );
      return;
    }
    this.selectedUser.emit(this.selection.selected)
  }

  approvePayment(row) {
    let rows = this.selection.selected.length ? this.selection.selected : row;
    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        info: rows,
        type: this.type,
        action: this.keys[this.keys.length - 1]
      },
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
    });

  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.renderedData.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.renderedData.forEach((row) =>
        this.selection.select(row)
      );
  }

  addTrackingNumber() {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        info: this.selection.selected,
        type: this.type,
        action: 'addTracking',
      },
    });
    this.subs.sink = dialogRef.afterClosed().subscribe((result) => {
    });
  }
  removeSelectedRows() {
    const totalSelect = this.selection.selected.length;
    this.selection.selected.forEach((item) => {
      const index: number = this.dataSource.renderedData.findIndex(
        (d) => d === item
      );
      this.exampleDatabase.dataChange.value.splice(index, 1);
      this.selection = new SelectionModel<any>(true, []);
    });
    this.showNotification(
      'snackbar-danger',
      totalSelect + ' Record Delete Successfully...!!!',
      'bottom',
      'center'
    );
  }
  public test(row, index, column) {
    return 'test'
  }
  public loadData() {
    this.exampleDatabase = new AdvanceTableService(this.httpClient);
    if (this.type === 'receipts') {
      // console.log(this.data)
    }
    this.dataSource = new ExampleDataSource(
      this.exampleDatabase,
      this.paginator,
      this.sort,
      this.data,
      this.keys,
      this.columns,
    );
    this.subs.sink = fromEvent(this.filter.nativeElement, 'keyup').subscribe(
      () => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      }
    );
  }
  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, '', {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName
    });
  }

  // context menu
  onContextMenu(event: MouseEvent, item: any) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item: item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }
}
export class ExampleDataSource extends DataSource<any> {
  filterChange = new BehaviorSubject('');
  get filter(): string {
    return this.filterChange.value;
  }
  set filter(filter: string) {
    this.filterChange.next(filter);
  }
  filteredData: any[] = [];
  renderedData: any[] = [];
  constructor(
    public exampleDatabase: AdvanceTableService,
    public paginator: MatPaginator,
    public _sort: MatSort,
    public data: any,
    public keys: string[],
    public columns: string[],
  ) {
    super();
    // Reset to the first page when the user changes the filter.
    this.filterChange.subscribe(() => (this.paginator.pageIndex = 0));
  }
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<any[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this.exampleDatabase.dataChange,
      this._sort.sortChange,
      this.filterChange,
      this.paginator.page
    ];
    this.exampleDatabase.getAllAdvanceTables(this.data);
    return merge(...displayDataChanges).pipe(
      map(() => {
        // Filter data
        if (this.exampleDatabase.data) {
        this.filteredData = this.exampleDatabase.data
          .slice()
          .filter((advanceTable: any) => {
            let searchStr: string;
            for (let i = 0; i < this.keys.length - 1; i++) {

              if (!isNil(advanceTable[this.keys[i]])) searchStr += advanceTable[this.keys[i]].toString();
            }
            if (searchStr) {
              searchStr.toLowerCase();
              return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
            }
          });
        // Sort filtered data
        const sortedData = this.sortData(this.filteredData.slice());
        // Grab the page's slice of the filtered sorted data.
        const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
        this.renderedData = sortedData.splice(
          startIndex,
          this.paginator.pageSize
        );
        return this.renderedData;
        } else return []
      })
    );
  }
  disconnect() { }
  /** Returns a sorted copy of the database data. */
  sortData(data: any[]): any[] {
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }
    let colIndex = this.columns.indexOf(this._sort.active);
    let colKey = this.keys[colIndex];

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';
      [propertyA, propertyB] = [a[colKey], b[colKey]];
      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;
      return (
        (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1)
      );
    });
  }
}
