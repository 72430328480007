import { RouteInfo } from './sidebar.metadata';
import { ROLES } from '../../shared/roles'
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'fa-chart-bar',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN, ROLES.BUYER],
    submenu: []
  },
  {
    path: 'deals',
    title: 'Deals',
    icon: 'fa-shopping-bag',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN, ROLES.BUYER],
    submenu: []
  },
  {
    path: 'commitments',
    title: 'Commitments',
    icon: 'fa-clipboard-list',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN, ROLES.BUYER],
    submenu: []
  },
  {
    path: 'payments',
    title: 'Payments',
    icon: 'fa-money-bill',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN, ROLES.BUYER],
    submenu: []
  },
  {
    path: 'disputes',
    title: 'Disputes',
    icon: 'fa-not-equal',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN, ROLES.BUYER],
    submenu: []
  },
  {
    path: 'shipments',
    title: 'Shipments',
    icon: 'fas fa-box-open',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.BUYER, ROLES.ADMIN],
    submenu: []
  },
  {
    path: 'tickets',
    title: 'Tickets',
    icon: 'fa-ticket-alt',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN, ROLES.BUYER],
    submenu: []
  },
  {
    path: 'receipts',
    title: 'Receipts',
    icon: 'fa-receipt',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN, ROLES.BUYER],
    submenu: []
  },
  {
    path: 'users',
    title: 'Users',
    icon: 'fa-users',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN],
    submenu: []
  },
  {
    path: 'items',
    title: 'Items',
    icon: 'fa-list-alt',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN],
    submenu: []
  },
  {
    path: 'serials',
    title: 'Serials',
    icon: 'fa-list-alt',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN],
    submenu: []
  },
  {
    path: 'reports',
    title: 'Reports',
    icon: 'fa-list-alt',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN, ROLES.STAFF],
    submenu: []
  },
  {
    path: 'receiving',
    title: 'Receiving',
    icon: 'fa-check-square',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN, ROLES.STAFF],
    submenu: []
  },
  // {
  //   path: 'profile',
  //   title: 'Profile',
  //   icon: 'shopping-bag',
  //   class: '',
  //   groupTitle: false,
  //   allowedUsers: [ROLES.ADMIN, ROLES.BUYER],
  //   submenu: []
  // },
  {
    path: 'faqs',
    title: 'Faqs',
    icon: 'fa-question-circle',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN, ROLES.BUYER],
    submenu: []
  },
  {
    path: 'termsandconditions',
    title: 'Terms & conditions',
    icon: 'fa-info-circle',
    class: '',
    groupTitle: false,
    allowedUsers: [ROLES.ADMIN, ROLES.BUYER],
    submenu: []
  },
];
