import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SnackbarService } from '../../shared/snackbar.service';
import { DisputesService } from './payment-dispute.service'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GlobalConsts } from 'src/app/shared/global-consts';
@Component({
  selector: 'app-payment-dispute-dialog',
  templateUrl: './payment-dispute-dialog.component.html',
  styleUrls: ['./payment-dispute-dialog.component.scss'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class PaymentDisputeDialogComponent implements OnInit {

  action: string;
  dialogTitle: string;
  info: any;
  type: string;
  public isLoading = true;
  submitted = false;
  isTrackingForm: boolean = false;
  disputeForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<PaymentDisputeDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private snackbar: SnackbarService,
    private service: DisputesService,
  ) {
    this.action = data.action;
    this.isTrackingForm = true;
  }
  ngOnInit(): void {
    this.disputeForm = this.formBuilder.group({
      currentAmount: [this.data.info.totalPrice, Validators.required],
      correctAmount: ['', [Validators.required]],
      notes: [''],
    })
  }

  get f() {
    return this.disputeForm.controls;
  }

  createDispute() {
    this.service.createDispute({
      correctAmount: this.disputeForm.value.correctAmount,
      currentAmount: this.disputeForm.value.currentAmount,
      notes: this.disputeForm.value.notes,
      receiptId: this.data.info._id,
    }).subscribe((res: any) => {
      this.snackbar.showNotification(
        'snackbar-success',
        res.message,
        'bottom',
        'center'
      );
      this.dialogRef.close();
    }, (error) => {
      let message = error ? error : GlobalConsts.error;
      this.snackbar.showNotification(
        'snackbar-danger',
        message,
        'bottom',
        'center'
      );
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    this.submitted = true;
    if (this.disputeForm.invalid) {
      return;
    } else {
      this.createDispute();
    }
  }

  public get title(): string {
    let heading = 'Create Payment Dispute'
    return heading
  }
}
