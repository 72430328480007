import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import {
  UntypedFormControl,
  Validators,
  UntypedFormGroup,
  UntypedFormBuilder
} from '@angular/forms';
import { UserService } from '../../../users-list/users-list/users-list.service';
import { PaymentsService } from '../../../payments/payments.service';
import { ShipmentsService } from '../../../Shipments/Shipment.service';
import { SnackbarService } from '../../../shared/snackbar.service';
import { DealService } from '../../../manage_deals/deal.service';
import { DisputesService } from '../../../receipts/payment-dispute-dialog/payment-dispute.service';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { GlobalConsts } from 'src/app/shared/global-consts';
import * as moment from 'moment';
@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.sass'],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class FormDialogComponent {
  action: string;
  dialogTitle: string;
  advanceTableForm: UntypedFormGroup;
  info: any;
  type: string;
  isUserEditForm: boolean = false;
  isUserBalanceForm: boolean = false;
  isPaymentForm: boolean = false;
  isTrackingForm: boolean = false;
  isCommitCountForm: boolean = false;
  isDisputeForm: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private paymentService: PaymentsService,
    private snackbar: SnackbarService,
    private shipmentService: ShipmentsService,
    private dealService: DealService,
    private disputeService: DisputesService,
  ) {
    // Set the defaults
    console.log(this.data)
    this.action = data.action;
    if (data.type === 'users') {
      if (this.action === 'Edit' || this.action === 'Approve') {
        this.isUserEditForm = true;
        this.advanceTableForm = this.createUserApproveForm();
      }
      else if (this.action === 'Balance'){
        this.isUserBalanceForm = true;
        this.advanceTableForm = this.createUserBalanceForm();
      }
      }
     
    else if (data.type === 'payments') {
      this.isPaymentForm = true;
      this.advanceTableForm = this.createPaymentApproveForm();
    }
    else if (data.action === 'addTracking') {
      this.isTrackingForm = true;
      this.advanceTableForm = this.createAddTrackingForm();
    }
    else if (data.type === 'commitments') {
      this.isCommitCountForm = true;
      this.advanceTableForm = this.createCommitCountForm();
    }  else if (data.type === 'disputes') {
      this.isDisputeForm = true;
      this.advanceTableForm = this.createDisputeApproveForm();
    }
  }
  formControl = new UntypedFormControl('', [
    Validators.required
  ]);
  getErrorMessage() {
    return this.formControl.hasError('required')
      ? 'Required field'
      : 'Something went wrong';
  }
  createUserApproveForm(): UntypedFormGroup {
    return this.fb.group({
      role: [this.data.info.role, Validators.required]
    });
  }
  createUserBalanceForm(): UntypedFormGroup {
    return this.fb.group({
      claimedBalance: [this.data?.info?.Balance?.ClaimedBalance, Validators.required],
      UnClaimedBalance: [this.data?.info?.Balance?.totalBalance, Validators.required],
      totalBalance: [this.data?.info?.Balance?.totalBalance, Validators.required],
    });
  }

  createDisputeApproveForm(): UntypedFormGroup {
    return this.fb.group({
      approve: ['Approve', Validators.required]
    });
  }

  createPaymentApproveForm(): UntypedFormGroup {
    return this.fb.group({
      approve: ['Approve', Validators.required]
    });
  }

  createAddTrackingForm(): UntypedFormGroup {
    return this.fb.group({
      trackingNo: ['', Validators.required]
    });
  }

  createCommitCountForm(): UntypedFormGroup {
    return this.fb.group({
      newCount: [this.data.info.count, [Validators.required, this.numberOnlyValidator]]
    });
  }
  numberOnlyValidator(control) {
    const numberPattern = /^[0-9]*$/;
    if (!numberPattern.test(control.value)) {
      return { numberOnly: true };
    }
    return null;
  }

  submit() {
    // emppty stuff
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  public confirmApprove(): void {
    if (this.advanceTableForm.valid) {
      if (this.isUserEditForm) this.approveUser();
      else if (this.isUserBalanceForm) this.editBalance();
      else if (this.isPaymentForm) this.approvePayment();
      else if (this.isTrackingForm) this.createShipment();
      else if (this.isCommitCountForm) {
      if (this.advanceTableForm.valid) {
        this.editCommitCount();
        this.dialogRef.close(1);
      }
    }
      else if (this.isDisputeForm) this.approveDispute();
    }
  }

  private editCommitCount(): void {
    // console.log(this.data)
    const count = parseInt(this.advanceTableForm.controls.newCount.value);
    const capacityPerPerson = this.data.info.itemObj.capacityPerPerson;
    const capacity = this.data.info.itemObj.capacity;
    const spaceTaken = this.data.info.itemObj.spaceTaken;
    if (count <= 0) {
      this.snackbar.showNotification(
        'snackbar-danger',
        `Count should be greator than zero`,
        'bottom',
        'center'
      );
      this.advanceTableForm.controls.newCount.setErrors({ required: true, message: 'Count is required and should be greater than 0' });
      return;
    }
    if (count + spaceTaken > capacity) {
      this.advanceTableForm.controls.newCount.setErrors({ countRange: true, message: `Capacity for only ${capacity-spaceTaken} Items left.` });
      this.snackbar.showNotification(
        'snackbar-danger',
        `You can only commit ${capacityPerPerson} items`,
        'bottom',
        'center'
      );
      return;
    } else if (count > capacityPerPerson) {
      this.advanceTableForm.controls.newCount.setErrors({ countRange: true, message: `You can only commit ${capacityPerPerson} items` });
      this.snackbar.showNotification(
        'snackbar-danger',
        `You can only commit ${capacityPerPerson} items`,
        'bottom',
        'center'
      );
      return;
    } else if (spaceTaken >= capacity) {
      this.snackbar.showNotification(
        'snackbar-danger',
        `This Item has reached it's full capacity. No more commits are accepted`,
        'bottom',
        'center'
      );
      this.advanceTableForm.controls.newCount.setErrors({ countRange: true, message: `This Item has reached it's full capacity. No more commits are accepted` });
      return;
    } else {
    this.dealService.updateCommitCount(
      {newCount: parseInt(this.advanceTableForm.controls.newCount.value)},
      this.data.info.id,
    ).subscribe((res: any) => {
      this.snackbar.showNotification(
        'snackbar-success',
        res.message,
        'bottom',
        'center'
      );
    }, (error) => {
      let message = error ? error : GlobalConsts.error;
      this.snackbar.showNotification(
        'snackbar-danger',
        message,
        'bottom',
        'center'
      );
    });
  }
  }

  private createShipment(): void {
    let commits: string[] = [];
    this.data.info.map((row: any) => {
      commits.push(row.id)
    });
    this.shipmentService.createShipment({
      commits,
      tracking_id: this.advanceTableForm.controls.trackingNo.value,
      nonCommitted: commits.length > 0 ? true : false,
    },
    ).subscribe((res: any) => {
      this.snackbar.showNotification(
        'snackbar-success',
        res.message,
        'bottom',
        'center'
      );
    }, (error) => {
      let message = error ? error : GlobalConsts.error;
      this.snackbar.showNotification(
        'snackbar-danger',
        message,
        'bottom',
        'center'
      );
    });
  }

  private editBalance(): void {
    this.userService.updateBalance({
      totalBalance: this.advanceTableForm.controls.totalBalance.value,
      ClaimedBalance: this.advanceTableForm.controls.claimedBalance.value,
      UnClaimedBalance: this.advanceTableForm.controls.UnClaimedBalance.value,
    },
    this.data.info?._id
    ).subscribe((res: any) => {
      this.snackbar.showNotification(
        'snackbar-success',
        res.message,
        'bottom',
        'center'
      );
    }, (error) => {
      let message = error ? error : GlobalConsts.error;
      this.snackbar.showNotification(
        'snackbar-danger',
        message,
        'bottom',
        'center'
      );
    });
  }

  private isArray(value: any): boolean {
    return Array.isArray(value);
  }


  private approveUser(): void {
    let ids = [];
    if (this.isArray(this.data.info)) {
      this.data.info.map(user => {
        ids.push(user._id)
      })
    } else ids = [this.data.info._id]
    
    this.userService.updateUser({
      newRole: this.advanceTableForm.controls.role.value,
      verified: "true",
      sendMail: true,
      ids,
    },
    ).subscribe((res: any) => {
      this.snackbar.showNotification(
        'snackbar-success',
        res.message,
        'bottom',
        'center'
      );
    }, (error) => {
      let message = error ? error : GlobalConsts.error;
      this.snackbar.showNotification(
        'snackbar-danger',
        message,
        'bottom',
        'center'
      );
    });
  }

  private approveDispute() {
    const val = this.advanceTableForm.controls.approve.value;
    this.disputeService.updateDispute({
      status: val,
    }, this.data.info._id).subscribe((res: any) => {
      this.snackbar.showNotification(
        'snackbar-success',
        res.message,
        'bottom',
        'center'
      );
    }, (error) => {
      let message = error ? error : GlobalConsts.error;
      this.snackbar.showNotification(
        'snackbar-danger',
        message,
        'bottom',
        'center'
      );
    });
  }

  private approvePayment(): void {
    let ids = [];
    if (this.isArray(this.data.info)) {
      this.data.info.map(user => {
        ids.push(user.id)
      })
    } else ids = [this.data.info.id]
    const val = this.advanceTableForm.controls.approve.value;
    this.paymentService.updatePayment({
      isApproved: val === 'approve',
      isDenied: val === 'deny',
      ApprovalDate: moment().toISOString(),
      ids,
    },
    ).subscribe((res: any) => {
      this.snackbar.showNotification(
        'snackbar-success',
        res.message,
        'bottom',
        'center'
      );
    }, (error) => {
      let message = error ? error : GlobalConsts.error;
      this.snackbar.showNotification(
        'snackbar-danger',
        message,
        'bottom',
        'center'
      );
    });
  }

  public get title(): string {
    let heading = ''
    if (this.isPaymentForm) heading = 'Approve'
    else if (this.isUserEditForm || this.isUserBalanceForm) {

      if (this.data.action === 'Approve') {
        heading = 'Approve Role for';
      } else if (this.data.action === 'Edit') {
        heading = 'Edit Role for';
      } else if (this.data.action === 'Balance') {
        heading = 'Edit Balance for';
      } 
      heading = heading + ' ' + this.data.info.name
    }
    return heading

  }
}
