<section class="content">
  <div class="container-fluid">
    <div class="row clearfix">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="header">
            <h2><strong>Terms and Conditions</strong></h2>
          </div>
          <div class="body">
            <p>These terms and conditions outline the rules and regulations for the use of the website located at
              buygetrewards.com.</p>
            <p>By accessing this website, you are assumed to accept these terms and conditions in full. If you do not accept all
              of the
              terms and conditions stated on this page, please do not continue to use buygetrewards.com's website.</p>
          
            <h2>Terminology</h2>
            <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or
              all
              Agreements: "Client," "You" and "Your" refer to you, the person accessing this website and accepting the Company's
              terms and conditions. "The Company," "Ourselves," "We," "Our" and "Us" refer to our Company. "Party," "Parties,"
              or "Us" refer to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer,
              acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the
              most appropriate manner, whether by formal meetings of a fixed duration or any other means, for the express purpose
              of meeting the Client's needs in respect of provision of the Company's stated services/products, in accordance with
              and subject to the prevailing law of the United States. Any use of the above terminology or other words in the
              singular,
              plural, capitalization, and/or he/she or they are taken as interchangeable and therefore as referring to the same.
            </p>
          
            <h2>Cookies</h2>
            <p>We use cookies on buygetrewards.com's website. By using this website, you consent to the use of cookies in
              accordance
              with buygetrewards.com's privacy policy.</p>
            <p>Most modern-day interactive websites use cookies to enable us to retrieve user details for each visit. Cookies are
              used in some areas of our site to enable the functionality of this area and ease of use for those people visiting.
              Some of our affiliate/advertising partners may also use cookies.</p>
          
            <h2>License</h2>
            <p>Unless otherwise stated, buygetrewards.com and/or its licensors own the intellectual property rights for all
              material
              on buygetrewards.com. All intellectual property rights are reserved. You may view and/or print pages from
              https://www.buygetrewards.com
              for your own personal use, subject to restrictions set in these terms and conditions.</p>
            <p>You must not:</p>
            <ul>
              <li>Republish material from https://www.buygetrewards.com</li>
              <li>Sell, rent, or sublicense material from https://www.buygetrewards.com</li>
              <li>Reproduce, duplicate, or copy material from https://www.buygetrewards.com</li>
              <li>Redistribute content from buygetrewards.com (unless content is specifically made for redistribution).</li>
            </ul>
          
            <h2>Hyperlinking to our Content</h2>
            <p>The following organizations may link to our website without prior written approval:</p>
            <ul>
              <li>Government agencies</li>
              <li>Search engines</li>
              <li>News organizations</li>
              <li>Online directory distributors when they list us in the directory may link to our website in the same manner as
                they hyperlink to the websites of other listed businesses</li>
              <li>Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity
                fundraising groups which may not hyperlink to our website.
              </li>
          
            </ul>
            <p>These organizations may link to our home page, publications, or other website information as long as the link:</p>
            <ul>
              <li>Is not in any way misleading</li>
              <li>Does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services
              </li>
              <li>Fits within the context of the linking party's site.</li>
            </ul>
            <p>We may consider and approve in our sole discretion other link requests from the following types of organizations:
            </p>
            <ul>
              <li>Commonly-known consumer and/or business information sources such as Chambers of Commerce, American Automobile
                Association, AARP, and Consumers Union</li>
              <li>Dot.com community sites</li>
              <li>Associations or other groups representing charities, including charity giving sites</li>
              <li>Online directory distributors</li>
              <li>Internet portals</li>
              <li>Accounting, law, and consulting firms whose primary clients are businesses.</li>
            </ul>
            <h2>Limitations of Liability</h2>
            <p>buygetrewards.com will not be liable to you (whether under the law of contact, the law of torts or otherwise) in
              relation to the contents of, or use of, or otherwise in connection with, this website for any indirect, special or
              consequential loss; or for any business losses, loss of revenue, income, profits or anticipated savings, loss of
              contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.
            </p>
            <p>These limitations of liability apply even if buygetrewards.com has been expressly advised of the potential loss.
            </p>
            <h2>Exceptions</h2>
            <p>Nothing in this website disclaimer will exclude or limit any warranty implied by law that it would be unlawful to
              exclude or limit; and nothing in this website disclaimer will exclude or limit buygetrewards.com's liability in
              respect of any:</p>
            <ul>
              <li>death or personal injury caused by buygetrewards.com's negligence;</li>
              <li>fraud or fraudulent misrepresentation on the part of buygetrewards.com; or
                matter which it would be illegal or unlawful for buygetrewards.com to exclude or limit, or to attempt or purport
                to exclude or limit, its liability.</li>
            </ul>
            <h2>Reasonableness</h2>
            <p>By using this website, you agree that the exclusions and limitations of liability set out in this website
              disclaimer are reasonable.
          
              If you do not think they are reasonable, you must not use this website.</p>
            <h2>Other parties</h2>
            <p>You accept that, as a limited liability entity, buygetrewards.com has an interest in limiting the personal
              liability of its officers and employees. You agree that you will not bring any claim personally against
              buygetrewards.com's officers or employees in respect of any losses you suffer in connection with the website.</p>
            <p>Without prejudice to the foregoing paragraph, you agree that the limitations of warranties and liability set out in
              this website disclaimer will protect buygetrewards.com's officers, employees, agents, subsidiaries, successors,
              assigns and sub-contractors as well as buygetrewards.com.</p>
            <h2>Unenforceable provisions</h2>
            <p>If any provision of this website disclaimer is, or is found to be, unenforceable under applicable law, that will
              not affect the enforceability of the other provisions of this website disclaimer.</p>
            <h2>Indemnity</h2>
            <p>You agree to indemnify and hold buygetrewards.com, its affiliates, officers, agents, employees, and partners
              harmless from any claims, demands, damages, liabilities, expenses or harms, including attorneys fees, made by any
              third-party due to or arising out of your use of the website or the Service or your violation of these Terms of Use
              or any rights of another.</p>
            <h2>Governing Law and Jurisdiction</h2>
            <p>These Terms shall be governed and construed in accordance with the laws of the United States, without regard to its
              conflict of law provisions.</p>
            <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If
              any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these
              Terms will remain in effect.</p>
            <h2>Changes</h2>
            <p>buygetrewards.com reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a
              revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What
              constitutes a material change will be determined at our sole discretion.</p>
            <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the
              revised terms. If you do not agree to the new terms, please stop using the Service.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at info@buygetrewards.com.</p>
          </div> 
        </div>
      </div>
    </div>
  </div>
</section>