import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService} from '../../shared/user.service';
import { environment } from 'src/environments/environment';
import { IUser } from '../../shared/common-interface';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUser: Observable<IUser>;
  public url = environment.apiUrl;
  private currentUserSubject: BehaviorSubject<IUser>;

  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      {token: JSON.parse(localStorage.getItem('currentUser'))}
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): IUser {
    return this.currentUserSubject.value;
  }
  
  login(data: any) {
    return this.http.post<any>(this.url+"/user/sign-in", data, {
      observe: 'response',
      headers: new HttpHeaders().set('Content-Type', "application/json")
    }).pipe(
        map((response: any) => {
          let token = response.headers.get('auth_token');
          let user = response.body;
          // localStorage.setItem('currentUser', JSON.stringify(token));
          this.currentUserSubject.next({...user,token});
          this.userService.setUser({...user,token});
          return user;
        })
      );
  }


  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.userService.clear();
    return of({ success: false });
  }


  signUp(data: any): Observable<any> {
    return this.http.post(this.url+"/user/sign-up", data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    });
  }

  forgotPassword(data: any): Observable<any> {
    return this.http.post(this.url+"/user/forgot-password", data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    });
  }

  setUser(): Observable<any>{
    return this.http.get(this.url+"/user/me").pipe(map((res: any) => res.user));
  }
}
