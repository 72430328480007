
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.currentUserValue.token) {
      // User is authenticated, allow navigation
      return true;
    } else {
      // User is not authenticated, redirect to login page
      this.router.navigate(['/authentication/signin'], {
        queryParams: { returnUrl: state.url }
      });
      return false;
    }
  }
}
