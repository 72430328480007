import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { IUser ,transformUsers } from '../../shared/common-interface';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public url = environment.apiUrl;

  constructor(
    private http: HttpClient,
  ) {
  }

  public getUsers(): Observable<IUser[]> {
    return this.http.get(this.url+"/user/get-users")
    .pipe(
      map(transformUsers.bind(this))
    )
  }

  updateUser(data: any): Observable<any> {
    return this.http.patch(this.url+"/user/upgrade-verify", data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    });
  }

  updateBalance(data: any, id: any): Observable<any> {
    return this.http.patch(this.url+"/user/update-balance/"+id, data, {
      headers: new HttpHeaders().set('Content-Type', "application/json")
    });
  }

}