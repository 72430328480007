import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { commitmentService } from '../../commitments/commitments.service';
import { ICommitmentRow, transformAllTableData } from '../../shared/common-interface';

import { MAT_DATE_LOCALE } from '@angular/material/core';
@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class ShipmentDialogComponent implements OnInit {
  action: string;
  dialogTitle: string;
  info: any;
  type: string;
  public committments: ICommitmentRow[] = [];
  public isLoading = true;
  public alreadyAddedCommits: ICommitmentRow[] = [];
  columns = ['Select', 'Date', 'Item', 'Count', 'Status',  'Price', 'Commission', 'Total']
  keys = ['', 'date', 'item', 'count', 'status', 'price', 'commission', 'total']

  isTrackingForm: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ShipmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    // private snackbar: SnackbarService,
    // private shipmentService: ShipmentsService,
    private commitsService: commitmentService,
  ) {
    // Set the defaults
    this.action = data.action;
    this.isTrackingForm = true;
    // this.advanceTableForm = this.createAddTrackingForm();
  }
  ngOnInit(): void {
    this.alreadyAddedCommits = transformAllTableData(this.data.info.commits)
    this.commitsService.getCommitmentsWithMissingTrackingNo().subscribe((res) => {
      this.alreadyAddedCommits.length > 0 ? this.committments.push(...this.alreadyAddedCommits, ...res) : this.committments.push(...res)
      this.isLoading = false;
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public get title(): string {
    let heading = 'Edit Shipment'
    return heading
  }
}
