import { Injectable } from '@angular/core';
import { IBalance, IUser } from './common-interface';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  public user: IUser
  
  public setUser(user: IUser): void {
    this.user = user;
  }

  public get userName(): string {
    return this.user?.name;
  }

  public get userEmail(): string {
    return this.user?.email;
  }

  public get userRole(): string {
    return this.user?.role;
  }

  public get userBalance(): IBalance {
    return this.user.Balance;
  }

  public get userId(): string {
    return this.user._id;
  }

  public get userMinId(): string {
    return this.user.userId;
  }

  public clear(): void {
    this.user = null;
  }

  public get isAdmin(): boolean {
    return this.user.role === 'admin';
  }

  public get isBuyer(): boolean {
    return this.user.role === 'buyer';
  }
}