<section class="content">
  <div class="container-fluid">
    <div class="row clearfix">
      <!-- Basic Examples -->
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="header">
            <h2>
              <strong>FAQs</strong>
              <small>Find your solutions from here.</small>
            </h2>

          </div>
          <div class="body">
            <div class="row clearfix">
              <div class="col-xs-12 ol-sm-12 col-md-12 col-lg-12">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Q1. What is an alias?
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                        <div class="panel-body">
                          An alias is an additional name to your user account.
                          It is mainly used for packages you deliver directly to us.
                          All packages received with your alias will be added to your user account.
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>

                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Q2. How do i view my receipts?
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                        <div class="panel-body">
                          Go to the receipts tab.
                          Click on the download icon by the receipt to view the pdf receipt.
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel> 
                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- #END# Basic Examples -->
    </div>
  </div>
</section>
