<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="body">

            <div class="responsive_table">
              <div class="materialTableHeader">
                <div class="row">
                  <div class="col-8">
                    <ul class="header-buttons-left ms-0">
                      <li class="tbl-search-box">
                        <label for="search-input"><i class="material-icons search-icon">search</i></label>
                        <input placeholder="Search" type="text" #filter class="browser-default search-field"
                          aria-label="Search box">
                      </li>
                      <li *ngIf="shouldShowAdd" class="tbl-header-btn">
                        <div class="m-l-10" matTooltip="ADD">
                          <button color="primary">
                            <mat-icon class="col-white">add</mat-icon>
                          </button>
                        </div>
                      </li>
                      <li class="tbl-header-btn">
                        <div *ngIf="type === 'commitments'" class="m-l-10" [hidden]="!selection.hasValue()"
                          matTooltip="Add tracking number for selected commit">
                          <button type="button" class="btn btn-outline-primary" (click)="addTrackingNumber()">Add
                            Tracking number</button>
                        </div>

                        <div *ngIf="type === 'selectUser'" class="m-l-10" [hidden]="!selection.hasValue()"
                        matTooltip="Select a user that shipped this order">
                        <button type="button" class="btn btn-outline-primary" (click)="selectUser()">Save</button>
                      </div>
                      </li>
                      <li *ngIf="type === 'editShipments'" class="tbl-search-box">
                        <form  [formGroup]="trackingForm">
                          <div class="d-flex a-center">
                            <input formControlName="trackingNo" placeholder="Tracking No" type="text" #filter class="browser-default search-field"
                              aria-label="Tracking No">
                            <div class="m-l-10">
                              <button mat-raised-button color="primary" [type]="submit" (click)="updateShipment()">Update</button>
                            </div>
                          </div>
                        </form>
                      </li>
                    </ul>
                  </div>
                  <div class="col-4">
                    <ul class="tbl-export-btn">
                      <li>
                        <div class="export-button m-l-10" matTooltip="XLSX">
                          <img src="assets/images/icons/xlsx.png" alt=""
                            (click)="exporter.exportTable('xlsx', {fileName: type, sheet: 'sheet1'})" />
                        </div>
                      </li>
                      <li>
                        <div class="export-button m-l-10" matTooltip="CSV">
                          <img src="assets/images/icons/csv.png" alt="" (click)="exporter.exportTable('csv', {fileName: type, sheet: 'sheet1'})" />
                        </div>
                      </li>
                      <li>
                        <div class="export-button m-l-10" matTooltip="JSON">
                          <img src="assets/images/icons/json.png" alt="" (click)="exporter.exportTable('json', {fileName: type, sheet: 'sheet1'})" />
                        </div>
                      </li>
                      <li>
                        <div class="export-button m-l-10" matTooltip="TXT">
                          <img src="assets/images/icons/txt.png" alt="" (click)="exporter.exportTable('txt', {fileName: type, sheet: 'sheet1'})" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <mat-table #table matTableExporter [dataSource]="dataSource" matSort class="mat-cell"
                #exporter="matTableExporter">
                <ng-container *ngFor="let column of columns; let i = index">
                  <ng-container *ngIf="column === 'Select'" [matColumnDef]="column">
                    <mat-header-cell *matHeaderCellDef [ngClass]="'tbl-col-width-per-6'">
                      <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()" color="primary"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" [ngClass]="'tbl-checkbox'">
                      </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row" [ngClass]="'tbl-col-width-per-6'">
                      <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)" color="primary" [ngClass]="'tbl-checkbox'">
                      </mat-checkbox>
                    </mat-cell>
                  </ng-container>
                  <ng-container *ngIf="column != 'Action' && column !== 'Select'" [matColumnDef]="column">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row[keys[i]]}}</mat-cell>
                  </ng-container>
                  <ng-container *ngIf="column == 'Action' && column !== 'Select'" [matColumnDef]="column">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</mat-header-cell>
                    <mat-cell *matCellDef="let row; let i=index;" class="pr-0 'tbl-col-width-per-10">
                      <ng-container *ngFor="let action of getActions(keys[keys.length-1]); let i = index">
                        <button mat-icon-button color="primary"  (click)="onActionClick(row, action)">
                          <mat-icon matTooltip="Edit" *ngIf="action == 'Edit'">mode_edit</mat-icon>
                          <mat-icon matTooltip="Edit Balance" *ngIf="action == 'Balance'">attach_money</mat-icon>
                          <mat-icon  matTooltip="View Details"*ngIf="action == 'Details'">more_horiz</mat-icon>
                          <mat-icon matTooltip="Create Dispute" *ngIf="action == 'Dispute'">money_off</mat-icon>
                          <mat-icon matTooltip="Approve" *ngIf="action == 'Approve'">verified_user</mat-icon>
                        </button>
                      </ng-container>
                    </mat-cell>
                  </ng-container>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns;" [style.cursor]="'pointer'" matRipple>
                </mat-row>
              </mat-table>
              <!-- Loading spinner -->

              <div class="no-results" [style.display]="dataSource.renderedData.length == 0 ? '' : 'none'">
                No results
              </div>
              <mat-paginator #paginator [length]="dataSource.filteredData.length" [pageIndex]="0" [pageSize]="25"
                [pageSizeOptions]="[25, 50, 75, 100]">
              </mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>